import { createStore } from "redux";

const initialState = {
  sidebarShow: "responsive",
  user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
  connected: false,
  joined: false,
  localVideoTracks: [],
  localAudioTracks: [],
  remoteTracks: {}
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    case "user":
      return { ...state, ...rest };
    case "meet":
      return {...state, ...rest}
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
