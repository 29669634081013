import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as authService from "../../../services/authService";
const MyAccount = ({ history }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    authService
      .logout()
      .then((response) => {
        dispatch({ type: "user", user: null });
        localStorage.clear();
        history.push("/login");
      })
      .catch((error) => {
        dispatch({ type: "user", user: null });
        localStorage.clear();
        history.push("/login");
      });
  }, []);
  return <div></div>;
};

export default MyAccount;
