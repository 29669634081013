import axios from "axios";
import { BASE_URL } from "./base_url";

export function login(data) {
  return axios.post(BASE_URL + "login", data);
}

export function register(data) {
  return axios.post(BASE_URL + "register", data);
}

export function forgotPassword(data) {
  return axios.post(BASE_URL + "forgot-password", data);
}

export function resetPassword(id, data) {
  return axios.post(BASE_URL + "reset-password/" + id, data);
}

export function accountUpdate(data) {
  return axios.put(BASE_URL + "account-update", data);
}

export function verifyEmail(path) {
  let url = new URL(path);
  let verifyUrl = url.pathname + url.search;

  if (process.env.NODE_ENV === "development") {
    verifyUrl = "http://localhost:8000" + verifyUrl;
  }

  if (process.env.NODE_ENV === "production") {
    if (process.env.REACT_APP_ENV === 'staging') {
      verifyUrl = "https://cpc-app-be.kijodev.com" + verifyUrl;
    } else {
      verifyUrl = "https://cpc-app-be.kijodev.com" + verifyUrl;
    }
  }
  return axios.get(verifyUrl);
}

export function resendEmail(data) {
  return axios.post(BASE_URL + "email/resend", data);
}

export function logout() {
  return axios.post(BASE_URL + "logout");
}
